.sidebar {
  width: 170px;
  min-height: 100vh;
  background-color: #D5E9E6;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  flex-shrink: 0;
}

.navigationButtons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.logoutButton {
  margin-top: auto;
}

.sidebarButton {
  width: 100%;
  padding: 10px 10px;
  margin: 5px 0;
  background-color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  text-align: center;
  font-size: 12px;
}

.sidebarButton:hover {
  background-color: #e0e0e0;
}

.sidebarButtonActive {
  color: #fff;
  background-color: #007BFF;
}