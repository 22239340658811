.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: calc(2px + 2vmin);
    color: #64a199;
}

.spinner {
    display: flex;
    align-items: center;
    justify-content: center;
}