.centeredModal .ant-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.centeredModal .ant-modal-content {
    margin: 0;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    transform: none;
}