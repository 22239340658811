.userPage {
  text-align: center;
}

.userPageLogo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .userPageLogo {
    animation: userPageLogoSpin infinite 20s linear;
  }
}

.userPageLink {
  color: #61dafb;
}

@keyframes userPageLogoSpin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rowItem {
  width: 643px;
  border-top: 1px solid #ccc;
}

.rowItem .item {
  display: flex;
  background-color: #fff;
}

.rowItem .item .title {
  min-width: 200px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px;
}

.rowItem .item .value {
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  min-width: 400px;
}

.antTableRow {
  cursor: pointer;
}