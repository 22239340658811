.appContainer {
    display: flex;
    min-height: 100vh;
}

.mainContent {
    flex-grow: 1;
    padding: 20px;
    overflow: auto;
}

@media (max-width: 768px) {
    .appContainer {
        flex-direction: column;
    }

    .sidebar {
        width: 100%;
        min-height: auto;
    }

    .mainContent {
        width: 100%;
        padding: 10px;
    }
}