.container {
  padding: 20px;
  font-size: 14px;
  line-height: 1.4;
  color: #333;
  background-color: #fff;
  font-family: Arial, sans-serif;
}

.filterContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.filterContainer label {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 12px;
}

select,
input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.userSection {
  margin-bottom: 20px;
}

.profileSection {
  margin-left: 20px;
  border-left: 4px solid #ddd;
  padding-left: 16px;
}

.jobSection {
  margin-left: 40px;
  font-style: arial;
}

.antTable {
  margin-top: 10px;
  font-size: 12px;
}

.antTableThead>tr>th {
  background-color: #fafafa;
  font-weight: bold;
  font-size: 12px;
}

.antTag {
  cursor: pointer;
  padding: 3px 8px;
  font-size: 12px;
  border-radius: 4px;
  margin-bottom: 4px;
  background-color: #D5E9E6;
  color: #292828;
  font-weight: bold;
}

.antModalContent {
  max-height: 80vh;
  overflow-y: auto;
}