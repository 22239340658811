.rowItem {
    width: 100%;
    border-top: 1px solid #ccc;
}

.rowItem .item {
    display: flex;
    background-color: #fff;
}

.rowItem .item .title {
    min-width: 200px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 10px;
}

.rowItem .item .value {
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 10px;
    flex-grow: 1;
}

.antTableRow {
    cursor: pointer;
}